
@function color($key) {
  @if map-has-key($allColors, $key) {
    @return map-get($allColors, $key);
  }
  @warn "Unknown `#{$key}` in $allColors.";
  @return null;
}

$allColors:(
    
    // Base Colors
    white: #ffffff,
    black: #000000,
    place: #aeaeae,
    mark: #ffff00,
    border: #cbcaca,
    body: #ffffff,

    // Success and Error Colors
    success: #7ac142,
    error: #d81212,
  
    // Theme Colors
    txt: #231f20,
    submenu: #7f7f7f,
    orange: #fc5000,

    // Social Media
    //facebook: #1877f2,
    // facebookold: #3b5998,
    //twitter: #1da1f2,
    // youtube: #ff0000,
    //instagrammagenta: #c32aa3,
    // instagramblue: #4c5fd7,
    // instagrampurple: #7232bd,
    // instagramorange: #f46f30,
    // instagramyellow: #ffdc7d,
    // googleblue: #4285f4,
    // googlered: #ea4335, 
    // googleyellow: #fbbc05, 
    // googlegreen: #34a853,
    // pinterest: #bd081c,
    // googleplus: #db4437,
    //linkedin: #007bb5,
    // vimeoblue: #1ab7ea,
    // tumblr: #2c4762,
    // snapchat: #fffc00,
    //whatsappgreen: #25d366,
    // whatsappteal1: #075e54,
    // whatsappteal2: #128c7e,
    // mastodon: #2b90d9,
    // apple: #a6b1b7,
    // amazon: #ff9900,
    // microsoftred: #f35022,
    // microsoftgreen: #80bb03,
    // microsoftblue: #03a5f0,
    // microsoftyellow: #ffb903,
    // periscope: #40a4c4,
    // foursquarepink: #f94877, 
    // foursquarenavy: #073282,
    // foursquareblue: #2d5be3, 
    // swarm: #ffa633,
    // medium: #02b875,
    // skypeblue: #00aff0,
    // skypedkblue: #0078d7,
    // android: #a4c639,
    // stumbleupon: #e94826,
    // flickrpink: #f40083,
    // flickrblue: #006add,
    // yahoo: #430297,
    // twitch: #6441a4,
    // soundcloud: #ff5500,
    // spotifygreen: #1ed760,
    // spotifydarkgreen: #1db954,
    //dribbble: #ea4c89,
    // slackpurple: #4a154b, 
    // slackblue: #36c5f0, 
    // slackgreen: #2eb67d, 
    // slackred: #e01e5a, 
    // slackyellow: #ecb22e, 
    // reddit: #ff5700,
    // deviantart: #05cc47,
    // pocket: #ee4056,
    // quora: #aa2200,
    // quorablue: #2b6dad,
    // slideshareorange: #e68523,
    // slideshareblue: #00a0dc,
    // fivehundredpx: #0099e5,
    // vk: #4a76a8,
    // listlyorange: #df6d46,
    // listlyblue: #52b1b3,
    // vine: #00b489,
    // steam: #171a21,
    // discord: #7289da,
    // telegram: #0088cc,
    // clarity: #61bed9  

    transparent: transparent !important
);

@each $colorName, $colorValue in $allColors{
  /*
  &.bg-#{$colorName}{
      background-color: $colorValue;
  }
  &.c-#{$colorName}{
    color: $colorValue;
  }
  */
    .bg-#{$colorName}{
        background-color: $colorValue;
    }
    .c-#{$colorName}{
        color: $colorValue;
    }
}
