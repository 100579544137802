@font-face {
  font-family: 'Futura Bld';
  src: url('../fonts/Futura-Bold.woff2') format('woff2'),
      url('./../fonts/Futura-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../fonts/bold/FuturaStd-Bold.woff2') format('woff2'),
      url('../fonts/bold/FuturaStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Futura Bk';
  src: url('./../fonts/Futura-Book.woff2') format('woff2'),
      url('./../fonts/Futura-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FutureLight';
  src: url('./../fonts/FutureLight.woff2') format('woff2'),
      url('./../fonts/FutureLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Theinhardt Pan Lt';
  src: url('./../fonts/TheinhardtPanLight-Regular.woff2') format('woff2'),
      url('./../fonts/TheinhardtPanLight-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Theinhardt Pan Md';
  src: url('./../fonts/TheinhardtPanMedium-Regular.woff2') format('woff2'),
      url('./../fonts/TheinhardtPanMedium-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



@mixin font($w){
  @if $w == 'fbld'{
    font-family: 'Futura Std', Tahoma;
    font-weight: bold;
  }
  @else if $w == 'fbk'{
    font-family: 'Futura Bk', Tahoma;
    font-weight: normal;
  }
  @else if $w == 'flt'{
    font-family: 'FutureLight', Tahoma;
    font-weight: 100;
  }
  @else if $w == 'tlt'{
    font-family: 'Theinhardt Pan Lt', Tahoma;
    font-weight: 300;
  }
  @else if $w == 'tmd'{
    font-family: 'Theinhardt Pan Md', Tahoma;
    font-weight: 500;
  }
}

:root{
  //font
  --fs-root: 20px;
  --ff-primary:  'Futura Bk', Tahoma;
}

@media(max-width: $mq-mobL){
  :root{
    --fs-root: 18px;
  }
}

.font-xl,
.font-lg,
.font-md,
.font-sm{
  *{
    font-size: inherit;
    line-height: inherit;
  }
}

.font-lg{
  font-size: 40px;
  line-height: 45px;
  // &.font-bold *{
  //   font-size: 125%;
  //   line-height: 1;
  // }
}

.font-md{
  font-size: 25px;
  line-height: 30px;
}

.font-sm{
  font-size: 18px;
  line-height: 20px;
}

.font-bold{
  @include font(fbld);
  letter-spacing: -1px;
}

.font-head,
.page-title{
  font-size: 25px;
}

@media(max-width: $mq-ipadL){
  .font-md{
    font-size: 20px;
    line-height: 22px;
  }
  .font-lg{
    font-size: 30px;
    line-height: 36px;
  }
}

@media(max-width: 768px){
  .font-lg{
    font-size: 22px;
    line-height: 28px;
  }

  .font-head,
  .page-title{
    font-size: 20px;
  }

  .font-md{
    font-size: 18px;
    line-height: 20px;
  }
}

@media(max-width: $mq-mobP){
  .font-head,
  .page-title{
    font-size: 18px;
  }

  .font-md{
    font-size: 16px;
    line-height: 18px;
  }
}