$containerWidth: 1280px;

:root{
  --container: #{$containerWidth};
  --containerGutter: 20px;
  --gutterX: 13px;
  --menuGutter: 38px;
  --headerGutter: 20px;
  --headerHeight: 66px;
  --fullH: calc(100vh - calc(var(--gutterX) * 2));
  --gapForMenu: 55px;
}


// body::-webkit-scrollbar {
//   display: none;
// }

// body 
//   -ms-overflow-style: none; 
//   scrollbar-width: none;
// }

#tmv__loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @extend .vh;
  z-index: 1111;
  background-color: color(black);
  @include d-flex(r, c, c);
  pointer-events: none;
  //opacity: 0.5;

  video{
    max-width: 100%;
    margin-top: 5.42px;
    // margin-left: -6.2%;
    // margin-top: -1px;
  }

   & ~ header {
    position: fixed;
    left: 0;
    right: 0;
    
    --theme: #{color(white)};
  
    .tmv__text{
      opacity: 1 !important;
    }
    .btn-hammenu{
      background-image: url('./../img/icons/hamW.svg');
      pointer-events: none;
    }

    & + #fullpage .btn-hammenu{
      background-image: url('./../img/icons/hamW.svg');
    }

  }
}

// #tmv__loader{
//   animation: loader 2s ease-in-out 1s forwards;
//   -webkit-animation: loader 2s ease-in-out 1s forwards;
// }


@-webkit-keyframes loader{
  0%{opacity: 1;}
  100%{opacity: 0;}
}
@keyframes loader{
  0%{opacity: 1;}
  100%{opacity: 0;}
}


figure img:hover{
  pointer-events: none;
}

b{
  @include font(fbld);
}

.btn--bottom-arw{
  width: 40px;
  height: 40px;
  background-image: url('./../img/icons/tmv-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @include transform(rotate(90deg));
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 111;
  // pointer-events: none;
  &.white{
      background-image: url('./../img/icons/tmv-arrowW.svg'); 
  }
  &.dir-up{
      pointer-events: auto !important;
      @include transform(rotate(-90deg));
  }
  &.dir-right{
    pointer-events: auto !important;
    @include transform(rotate(0deg));
  }
  @media(min-width: ($containerWidth + 1) ) {
      right: calc((calc(100vw - var(--container)) / 2) - 52px);
  }
  @media(max-width: $containerWidth){
      right: calc(1 * var(--containerGutter));
  }
  @media(max-width: $mq-mobL){
    width: 35px;
    height: 35px;
    bottom: 18px;
    right: 14px;
  }
}


.swiper-service--prev,
.swiper-service--next{
  width: 40px;
  height: 40px;
  background-image: url('./../img/icons/tmv-arrow.svg');
  background-repeat: no-repeat;
  background-position: 5px;
  background-size: contain;
  cursor: pointer;
  &.swiper-button-disabled{
    opacity: 0;
    pointer-events: none;
  }
  @media(max-width: $mq-mobL){
    width: 35px;
    height: 35px;
  }
}

.swiper-service--prev{
  @include transform(rotate(180deg));
}


.main-container{
  max-width: calc(var(--container) + calc(var(--containerGutter) * 2));
  margin: 0 auto;
  padding: 0 var(--containerGutter);
}

.content-area{
  padding-top: var(--gutterX);
  padding-bottom: var(--gutterX);
  a:hover{
    color: color(orange);
  }
  @media(min-width: ($containerWidth + 1 ) ){
    margin-left: calc(-1 * (calc(100vw - var(--container)) / 2));
    margin-right: calc(-1 * (calc(100vw - var(--container)) / 2));
    
    padding-left: calc(1 * (calc(100vw - var(--container)) / 2));
    padding-right: calc(1 * (calc(100vw - var(--container)) / 2));
  }
  @media(max-width: $containerWidth){
    margin-left: calc(-1 * var(--containerGutter));
    margin-right: calc(-1 * var(--containerGutter));
    
    padding-left: calc(1 * var(--containerGutter));
    padding-right: calc(1 * var(--containerGutter));
  }
  @media(max-width: $mq-ipadL){
    margin-left: auto;
    margin-right: auto;
    
    padding-left: 0;
    padding-right: 0;
  }
}

.logo-grid-2{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--containerGutter);
}

// TYPOGRAPHY
.section-title{
  @extend .font-lg;
  @media(max-width: 768px){
    font-size: 20px;
  }
}

p{
  line-height: 1.1;
}
.desc p{
  &:not(:last-child){
    margin-bottom: 1.4em; //1.2em;
  }
  br{
    margin-bottom: 0;
  }
}


// FULL PAGE ENABLED PAGES
body .fp-tableCell{
  vertical-align: top;
}
html{
  &.fp-enabled{
    header{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1111;
      .tmv__text{
        opacity: 0;
      }

      .menu-toggleBtn{
          @media(min-width: ($mq-ipadP + 1) ){
            pointer-events: none;
            z-index: -1;
          }
          .moveToContact{
            display: none;
          }
      }
      // .btn-hammenu{
      //   position: relative;
      //   pointer-events: none;
      //   @media(min-width: 1460px + 1){
      //     left: 47px;
      //   }
      // }
      & + .btn--bottom-arw{
        pointer-events: none !important;
      }
    } 
    // body.fp-viewing-0{
    //   header .tmv__text{
    //     opacity: 1;
    //     visibility: visible;
    //   }
    // }
    .menu-toggleBtn{
      position: absolute;
    }
    #fullpage{
      .menu-toggleBtn,
      .btn--bottom-arw{
        opacity: 0;
      }
    }
    .navitem-contact{
      display: none;
    }
    .navitem-contact-label{
      display: block;
      label{
        display: block; 
        cursor: pointer;
      }
    }
  } 
  // body.fp-viewing-0{
  //   .btn--bottom-arw{
  //     display: none;
  //   }
  // }
  &:not(.fp-enabled){
    header{
      position: relative;
      z-index: 9999;
      // background-color: color(white);
    }
    .btn--bottom-arw{
      pointer-events: none;
    }
    footer{
      overflow: hidden;
      margin-top: 100px;
      @media(max-width: $mq-mobL){
        margin-top: 0;
      }
    }
    .navitem-contact{
      display: block;
    }
    .navitem-contact-label{
      display: none;
    }
  }
}
.fullpage-wrapper{
  transition-timing-function: cubic-bezier(.23,1,.32,1) !important;
}



header{
  pointer-events: none;
  padding: var(--headerGutter) 0;
  --theme: #{color(txt)};
  color: var(--theme);
  &.white{
    --theme: #{color(white)};
    .btn-hammenu{
      background-image: url('./../img/icons/hamW.svg');
    }
    & + #fullpage .btn-hammenu{
      background-image: url('./../img/icons/hamW.svg');
    }
  }

  .main-container{
    @include d-flex(r, sb, fs);
  }

  .tmv__text{
    pointer-events: auto;
    @extend .font-head;
    margin-top: -4px;
    @include transition(0.2s);
    &[style="opacity: 0;"]{
      visibility: hidden;
    }
    @media(max-width: $mq-mobP){
      font-size: 20px;
    }
  }


  &.vhide{
    pointer-events: none;
    z-index: 99999999999999999 !important;
    .tmv__text{
      pointer-events: all;
    }
    .btn-hammenu{
      opacity: 0;
      transition: opacity 0.4s ease-in-out,;
      -webkit-transition: opacity 0.4s ease-in-out,;
      -moz-transition: opacity 0.4s ease-in-out,;
      -ms-transition: opacity 0.4s ease-in-out,;
      -o-transition: opacity 0.4s ease-in-out,;
    }
    @media(max-width: $mq-ipadP){
      position: fixed !important;
      .tmv__text{
        color: color(txt);
        opacity: 1 !important;
        visibility: visible;
        transition-delay: 0.4s;
        box-shadow: inset 0 0 0 50px #e6e6e6, -10px -10px 0 25px #e6e6e6;
      }
    }
  }
}



nav{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  @include transform(translateX(100%));
  @include transition(transform 0.4s ease-in-out);
  &.is-open{
    @include transform(translateX(0%));
  }

  .main-container{
    pointer-events: none;
  }
  .close-menu-label{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // cursor: pointer;
  }

  .menu-grid{
    pointer-events: auto;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: rgba(255, 255, 255, 0.95);
    padding: var(--headerGutter) var(--menuGutter) var(--menuGutter) var(--menuGutter);
    margin-left: 51.25%;
    @include d-flex(c, sb, fs);
    padding-bottom: 73px;
    
    @media(min-width: ($containerWidth + 1) ){
      margin-right: calc(-1 * (calc(100vw - var(--container)) / 2));
      padding-right: calc(1 * (calc(100vw - var(--container)) / 2));
    }
    @media(max-width: $containerWidth){
      margin-right: calc(-1 * var(--containerGutter));
      padding-right: calc(1 * var(--containerGutter));
    }
    @media(max-width: $mq-ipadP){
      width: 100%;
      overflow: auto;
      margin-left: 0;
      padding-left: calc(1 * var(--containerGutter));
      padding-bottom: var(--headerGutter);
      background-color: #e6e6e6;
    }
    
  }

  ul{
    list-style: none;
    @extend .font-lg;
    display: inline-block;
    li{
      &.has-submenu.actv{
        .submenu{
          height: calc(var(--ch) + 5px) !important;
          @include transition(0.3s ease);
        }
      }
    }
    a{
      @include transition(0.3s);
    }

    &.mainmenu{
      width: 50%;
      margin-top: -7px;
      & > li > a{
        &:hover, &:focus, &:active, &.actv{
          color: color(orange);
        }
      }
    }
    &.submenu{
      padding-left: 65px;
      width: 150%;
      color: color(submenu);
      @include transition(0.3s ease);
      overflow: hidden;
      // display: none;
      a{
        &:hover, &:focus, &:active, &.subnav-actv{
          color: color(txt);
        }
      }
    }
    @media(max-width: $mq-ipadP){
      &.mainmenu{
        margin-bottom: 50px;
      }
      &.submenu{
        width: 100%;
      }
    }
    @media(max-width: $mq-mobL){
      font-size: 30px;
    }
  } 

  .contact-info{
    p{
      // margin-bottom: 3px;
      a{
        font-family: inherit;
        font-weight: inherit;
        &:hover{
          color: color(orange);
        }
      }
    }
    .tm-contact{
      margin-top: 25px;
    }
  }
  @media(max-width: $mq-ipadP){
    .main-container{
      padding: 0;
    }
    ul.mainmenu{
      width: 100%;
      margin-top: 41px;
    }
  }
  @media(max-width: $mq-mobP){
    ul.submenu{
      width: 100%;
      padding-left: 40px;
    }
    .contact-info{
      p{
        line-height: 1.3;
      }
    }
  }
}



.menu-toggleBtn{
  display: inline-block;
  position: fixed;
  top: 19px;
  z-index: 111;
  pointer-events: auto;
  @media(min-width: (1400px + 1) ){
    right: calc(calc(1 * (calc(100vw - var(--container)) / 2)) - 47px);
  }
  @media(max-width: 1400px){
    right: var(--containerGutter);
  }
  @media(max-width: $mq-mobL){
    top: 18px;
  }
  #btn--menuToggle{
    opacity: 0;
    visibility: hidden;
  }
}

.btn-hammenu{
  width: 30px;
  height: 20px;
  background-image: url('./../img/icons/hamB.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  cursor: pointer;
  // @media(min-width: ($containerWidth + 1) ){
  //   position: relative;
  //   left: 47px;
  // }
  &.close{
    background-image: url('./../img/icons/closeB.svg');
  }
  // @media(max-width: $mq-mobL){
  //   width: 27px;
  //   height: 20px;
  // }
}

.btn-closemenu{
  width: 28px;
  height: 33px;
  background-image: url('./../img/icons/closeB.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  top: var(--headerGutter);
  right: var(--containerGutter);
  @media(min-width: ($containerWidth + 1) ){
    right: -25px;
  }
  @media(max-width: $mq-mobL){
    width: 20px;
    height: 17px;
  }
}

footer{
  .logo-grid-2{
    gap: var(--containerGutter) 108px;
  }
  .btn--b2t-cont{
    grid-column-start: 2;
  }
  .logo-grid-btm{
    width: 100%;
    display: grid;
    gap: var(--containerGutter);
    grid-template-columns: 1.03fr 0.5fr 0.5fr;
  }
  .inner-flex{
    @include d-flex(r, sb, s);
  }
  .logos{
    @include d-flex(c, sb, fs);
  }
  .flex-centerY{
    padding-bottom: 60px;
  }

  background-color: color(black);
  color: color(white);
  .logos img{
    @include imgW();
  }

  &[data-hammenu="white"]{
    .btn--bottom-arw{
        background-image: url('./../img/icons/tmv-arrowW.svg'); 
    }
    .btn-hammenu{
      background-image: url('./../img/icons/hamW.svg'); 
    }
  }

  @media(max-width: $mq-ipadP){
    article{
      margin-top: 2px;
    }
    .font-lg{
      font-size: 19px;
    }
    .logo-grid-2{
      gap: var(--containerGutter) ;
    }
    .logo-grid-2:nth-child(1){
      grid-template-columns: repeat(1, 1fr);
    }
    .inner-flex{
      @include flex-direction(column);
    }
    .logos{
      margin-top: 20px;
    }
  }

  @media(max-width: $mq-mobP){
    .logo-grid-2:nth-child(2){
      gap: var(--containerGutter) 40px;
      grid-template-columns: auto auto;
    }
    .logos{
      max-width: 105px;
    }
    p{
      line-height: 1.3;
    }
  }
}

.flex-centerY{
  height: var(--fullH);
  @include d-flex(c, sb, fs);
}


// HOME AND PRACTICE HERO SECTION
.tmv__logo{
  pointer-events: none;
  &:not(.sticky):not(.fixed){
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }
  &.fixed{
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }
  &.sticky{
    position: sticky;
    top: 50%;
    left: 0;
    @include transform(translateY(-50%));
    padding-right: 0.6px;
  }

  .logo-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

.tmv__hero{
  // margin: 0 -15px;
  counter-reset: slide-counter;

  // .swiper-slide{
  //   opacity: 0 !important;
  //   &.swiper-slide-duplicate-active,
  //   &.swiper-slide-active{
  //     opacity: 1 !important;
  //   }
  // }
  // @media(max-width: $mq-mobL){
  //   margin: 0;
  //   .swiper-slide > .main-container{
  //     padding: 0;
  //   }
  // }


  .page-title{
    color: color(white);
    position: absolute;
    z-index: 1;
    bottom: var(--headerGutter);
    @media(min-width: ($containerWidth + 1) ){
      left: calc(1 * (calc(100vw - var(--container)) / 2));
    }
    @media(max-width: $containerWidth){
      left: calc(1 * var(--containerGutter));
    }
  }

  .tmv__logo{
    pointer-events: none;
    z-index: 111;
  }

  .swiper-slide{
    background-color: color(black);
    counter-increment: slide-counter;
  }

  .hero-fig{
    position: relative;
    z-index: 11;
  }


  .project-link{
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

  figcaption{
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    padding: 40px 65px 20px 20px;
    color: color(white);
    @include d-flex(c, fe, fs);
    @include transition(0.3s ease-in-out);
    z-index: 1;
    @extend .font-head;
    // &::after{
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), transparent);
    //   z-index: -1;
    // }
  }

  .title{
    @include font(fbld);
    &::before{
      content: attr(data-counter);
      display: block;
    }
  }

  
  @media(min-width: ($mq-ipadL - 1) ){
    figcaption{
      opacity: 0;
      padding: 40px;
    }
    .title{
      span{
        @include transform(translateY(30px));
        opacity: 0;
      }
    }
    .hvr-forefront:hover{
      z-index: 111111111;
      figcaption{
        opacity: 1;
      }
      .title{
        span{
          @include transform(translateY(0px));
          opacity: 1;
          @include transition(0.5s);
          @include transition-delay(calc(0.1s * var(--i)));
        }
      }
    }  
  }


  @media(min-width: ($containerWidth + 1) ){
    .T figure.hero-fig{
      margin-right: calc(-1 * (calc(100vw - var(--container)) / 2));
    }

    .V figure.hero-fig{
      margin-left: calc(-1 * (calc(100vw - var(--container)) / 2));
    }

    .TM figure.hero-fig{
      margin-left: calc(-1 * (calc(100vw - var(--container)) / 2));
    }

    .MV figure.hero-fig{
      margin-right: calc(-1 * (calc(100vw - var(--container)) / 2));
    }

    .M-right figure.hero-fig{
      margin-right: calc(-1 * (calc(100vw - var(--container)) / 2));
    }

    .M-left figure.hero-fig{
      margin-left: calc(-1 * (calc(100vw - var(--container)) / 2));
    } 
  }

  @media(max-width: $containerWidth) and (min-width: ($mq-mobL + 1)){
    .T figure.hero-fig,
    .M-right figure.hero-fig,
    .MV figure.hero-fig{
      margin-right: calc(-1 * var(--containerGutter));
    }
    .V figure.hero-fig,
    .M-left figure.hero-fig,
    .TM figure.hero-fig{
      margin-left: calc(-1 * var(--containerGutter));
    }
  }


  @media(min-width: ($mq-mobL + 1) ){
    .T figure.hero-fig{
      margin-left: 16.35%;
    }

    .V figure.hero-fig{
      margin-right: 17%;
    }

    .M-right figure.hero-fig{
      margin-left: 51.25%;
    }

    .M-left figure.hero-fig{
      margin-right: 51.25%;
    } 

    .MV-half figure.hero-fig{
      margin-left: 34.1%; margin-right: 17%;
    } 

    .TM-half figure.hero-fig{
      margin-left: 15.4%; margin-right: 34.1%;
    } 
    
    .TM figure.hero-fig{
      margin-right: 34.1%;
    }

    .MV figure.hero-fig{
      margin-left: 34.1%;
    }
  }
  
  @media(max-width: $mq-mobL){
    .hero-fig{
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

//--- HOME AND PRACTICE HERO SECTION


// HOME PAGE
.tmv__intro{
  .logo-grid-2{
    grid-template-columns: 1.1fr 1fr;
  }
  article > *{
    opacity: 0;
  }
  strong{
    display: inline;
  }
  @media(max-width: $mq-ipadL){
    br{
      display: none;
    }
  }
  @media(max-width: $mq-ipadP){
    .logo-grid-2{
      grid-template-columns: repeat(1, 1fr);
      padding-right: var(--gapForMenu);
    }
  }
}



// PRACTICE PAGE
.tmv__practice{
   footer .logos{
   visibility: visible;
 }
}
.tmv__services{
  .tmv__service--grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @media(max-width: $mq-ipadP){
      grid-template-columns: repeat(1, 1fr);
      article{
        margin-bottom: calc(100vh - 100px);
      }
    }
  }
  
  .tmv__service--swiper,
  .tmv__service--grid{
    margin-top: 210px;
    article{
      opacity: 0;
      @include transition(opacity 0.3s ease-out);
    }
    @media(max-width: $mq-ipadL){
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  .tmv__service--swiper{
    margin-top: 50px;
    @media(max-width: $mq-ipadP){
      margin-top: 50px;
      article{
        min-height: calc(100vh - 110px);
        min-height: calc(calc(var(--vh, 1vh) * 100) - 110px);
        padding-bottom: 50px;
      }
    }
  }

  .swiper-service--nav{
    & > *{
      position: absolute;
      bottom: 0;
      z-index: 1111111;
    }
    .swiper-service--prev{
      left: 0;
    }
    .swiper-service--next{
      right: 0;
    }
  }
}



.tmv__team{
  padding-top: var(--containerGutter);
  figure{
    margin-bottom: 15px;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 31px;
    //@extend .font-md;
    &:not(:last-child){
      margin-bottom: 31px;
    }
  }

  .tmv__team--member{
    .--name,
    .--degination{
      opacity: 0;
    }
    & > figure{
      position: relative;
      cursor: pointer;
      margin-bottom: 7px;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 10px solid transparent;
      }
      @include transition(0.3s ease-in-out);
    }
    &.fade-in figure{
      opacity: 0.2;
    }

    &:hover figure::before{
      border-color: color(orange);
    }
    &.is-showing .tmv__team--member-dtls{ 
      opacity: 1;
      visibility: visible;
    }
  }

  @media(max-width: $mq-ipadP){
    figure{
      margin-bottom: 10px;
    }
    .grid{
      grid-template-columns: repeat(2, 1fr);
      gap: var(--containerGutter);
      &:not(:last-child){
        margin-bottom: var(--containerGutter);
      }
    }
  }
}
// .section.active{
//   .tmv__team--member{
//     .--name,
//     .--degination{
//       opacity: 1;
//       @include transition-delay( calc(501ms * var(--i) ) );
//     }
//   }
//   .tmv__services .tmv__service--grid{
//     & > article{
//       opacity: 1;
//       @include transition-delay( calc(501ms * var(--i) ) );
//     }
//   }
// }
.tmv__team--member-dtls{
  @include d-flex(c, c, c);
  padding-top: var(--headerGutter);
  padding-bottom: var(--headerGutter);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  // background-color: rgba(255, 255, 255, 0.95);

  background-color: color(black);
  color: (white);
  opacity: 0;
  visibility: hidden;
  @include transition(opacity 0.3s ease-in);


  .grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  figure{
    margin-bottom: 0;
  }

  .desc{
    margin-top: 50px;
    br{
      display: none;
    }
  }

  .main-container{
    position: relative;
  }

  .btn-closemenu{
    @media(min-width: ($containerWidth + 1) ){
      top: 17px;
      right: calc(calc(1 * (calc(100vw - var(--container)) / 2)) - 47px);
    }
    @include imgW();
  }

  @media(max-width: $mq-ipadP){
    padding-top: 60px;
    display: block;
  }

  @media(max-width: $mq-mobP){
    .grid{
      grid-template-columns: repeat(1, 1fr);
    }
    .desc{
      margin-top: 20px;
    }
  }
}

// JOURNAL PAGE
.tmv__journal{
  // .tmv__logo{
  //   .main-container{
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }
  & + footer{
    overflow: hidden;
    margin-top: 100px;
  }
  @media(max-width: 1400px){
    @include d-flex(c, fs, fs);
  }

  @media(max-width: $mq-mobL){
    margin-bottom: 50px;
  }
}
.tmv__journal--grids{
  position: relative;
  min-height: 100vh;
  margin-top: var(--logoOffsetTop);
  &:hover{
    .tmv__logo{
      pointer-events: none;
    }
  }
  video{
    max-width: 100%;
  }
  figure{
    display: inline-block;
    position: relative;
    z-index: 2;
  }
  .figdate{
    text-align: left;
    margin-top: 0.5em;
  }
  .figinfo{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.95);
    border-top: 15px solid color(orange);
    padding: 1em;
    text-align: left;
    opacity: 0;
    @include transition(opacity 0.3s linear);
    &::after{
      content: '';
      position: absolute;
      height: 100px;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 10px solid #fff;
      background: linear-gradient(0deg, #ffffff, transparent);
      opacity: 0.85;
      pointer-events: none;
    }
  }
  .grid-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1%;
    margin-left: 16.3%;
    margin-right: 16.4%;
    text-align: right;
    @media(min-width: ($containerWidth + 1) ){
      gap: 0 29px;
    }
  }
 .grid-3{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 2.3%;
    @media(min-width: ($containerWidth + 1) ){
      gap: 0 29px;
    }
 }
  .tmv__logo{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .tmv__journal--item{
    // border-left: 1px solid red;
    // border-right: 1px solid red;
    opacity: 0;
    margin-bottom: 50px;
    z-index: 1;
    &:hover{
      position: relative;
      z-index: 9;
      .figinfo{
        opacity: 1;
      }
    }
    &.TM{
      grid-column-start: 1;
    }

    &.MV{
      grid-column-start: 2;
    }
    &.T{
      grid-column-start: 1;
    }

    &.M{
      grid-column-start: 2;
    }

    &.V{
      grid-column-start: 3;
    }

    position: relative;
    .journal-link{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    figure{
      width: 100%;
      position: relative;
      z-index: 1;
      height: 0;
      padding-bottom: 100%;
      img, video{
        @include object(cover, top left);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }

  @media(max-width: $mq-mobL){
    font-size: 14px;
  }

  @media(max-width: $mq-mobP){
    font-size: 16px;

    .tmv__journal--item{
      margin-bottom: 20px;
    }
    .grid-2,
    .grid-3{
      display: block;
    }
    .grid-2{
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// PROJECT LIST PAGE
.tmv__projects{
  margin-top: 100px;
  counter-reset: slide-counter;
  ul{
    list-style: none;
    @extend .font-md;
    display: inline-block;

    .has-submenu{
      & > a{
        background-repeat: no-repeat;
        padding-left: 34px;
        background-size: 26px;
        background-position: center left;
        background-image: url(./../img/icons/tmv-arrow.svg);
        margin-left: -34px;
      }
      &.actv{
        & > a{
          background-size: 18px;
          background-image: url(./../img/icons/closeB.svg);
        }
        .submenu{
          // display: inline-block !important;
          height: var(--ch) !important;
          @include transition(0.3s ease);
          // @include transition-delay(20s);
        }
      }
      
      @media(max-width: 1400px){
        a{
          margin-left: 0;
          padding-left: 30px;
          background-position: -4px center;
        }
        &.actv{
          & > a{
            background-position: 0 center;
          }
        }
      }
    }
    
    a{
      margin-bottom: 1px;
      @include transition(color 0.3s);
    }
    &.mainmenu{
      margin-bottom: 22px;
    }
    &.submenu{
      width: 100%;
      overflow: hidden;
      margin-bottom: 8px;
      @include transition(0.3s ease);
      a{
        &:hover, &:focus, &.subnav-actv{
          color: color(orange);
        }
      }
    }
    
    @media(max-width: $mq-ipadP){
      &.mainmenu{
        margin-bottom: 15px;
      }
      &.submenu{
        margin-bottom: 0;
      }
    }
  } 
  @media(max-width: $mq-mobL){
    margin-top: 20px;
  }
}
.tmv__projects--grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px 33px;
  gap: 28px 33px;
  margin-bottom: 32px;
  
  .tmv__project--item{
    figcaption{
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      padding: 20px;
      color: color(white);
      @include d-flex(c, fe, fs);
      opacity: 0;
      @include transition(0.3s ease-in-out);
      border-top: 10px solid color(orange);
      z-index: 1;
      // &::after{
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), transparent);
      //   z-index: -1;
      // }
    }
    
    a{
      position: relative;
      &:hover figcaption{
        opacity: 1;
      }
    }
  }

  @media(max-width: 1400px){
    grid-gap: calc(var(--containerGutter) + 5px) var(--containerGutter);
    gap: calc(var(--containerGutter) + 5px) var(--containerGutter);
    margin-bottom: calc(var(--containerGutter) + 5px);
  }

  @media(max-width: $mq-ipadP){
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
    .tmv__project--item figcaption{
      font-size: 18px;
      padding: 15px;
    }
  }

  @media(max-width: $mq-mobP){
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--containerGutter);
    gap: var(--containerGutter);
    margin-bottom: var(--containerGutter);
    a, img{
      width: 100%;
    }
    // a{
    //   width: 100%;
    //   padding-bottom: 70%;
    //   height: 0;
    //   display: block;
    // }
    // img{
    //   @include object(cover, top);
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    // }
    .tmv__project--item figcaption{
      font-size: 16px;
    }
  }
}
.tmv__projects--grid:nth-child(odd){
  overflow: hidden;
  .tmv__project--item{
    &:nth-child(1){
      grid-column: 2/4;
      grid-row: 1;
      position: relative;
      top: -6px;
      figcaption{
        border-width: 16px;
      }
    }
    &:nth-child(3){
      margin-top: -64%;
    }
    @media(max-width: 1400px){
      &:nth-child(1){top: 0;}
    }
    @media(max-width: $mq-ipadP){
      &:nth-child(3) a{
        top: 4px;
      }
    }
    @media(max-width: $mq-mobP){
      &:nth-child(1){
        grid-column: auto;
        grid-row: inherit;
        top: 0; 
      }
      &:nth-child(3){
        margin-top: 0;
        a{
          top: 0;
        }
      }
    }
  }
}

.tmv__projects--grid:nth-child(even){
  overflow: hidden;
  .tmv__project--item{
    &:nth-child(1){
      grid-column: 1/3;
      position: relative;
      top: -4px;
      figcaption{
        border-width: 16px;
      }
    }
    &:last-child{
      margin-top: -29%;
    }
    @media(max-width: 1400px){
      &:nth-child(1){top: 0;}
    }
    @media(max-width: $mq-ipadP){
      &:last-child a{
        top: 6px;
      }
    }
    @media(max-width: $mq-mobP){
      &:nth-child(1){
        grid-column: auto;
        grid-row: inherit;
        top: 0; 
      }
      &:last-child{
        margin-top: 0;
        a{
          top: 0;
        }
      }
    }
  }
}
.tmv__project--item{
  counter-increment: slide-counter;
  .title{
    @include font(fbld);
    margin-top: 10px;
    &::before{
      // content: counter(slide-counter);
      content: attr(data-counter);
      display: block;
    }
  }
  opacity: 1;
  @include transition(opacity 0.3s ease-in);
  &.fade-in{
    opacity: 0.2;
  }
}

.tmv__related-projects{
  padding-top: var(--headerGutter);
  padding-bottom: var(--headerGutter);
  @include d-flex(r, c, c);
  .tmv__related-projects--grid{
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0 var(--containerGutter);
    justify-content: center;
    align-items: center;
    @media(max-width: $mq-mobP){
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
      gap: var(--containerGutter);
      margin-bottom: var(--containerGutter);
      a, span{
        display: block;
      }
      img{
        width: 100%;
      }
    }

  }
  .tmv__project--item {
    span{
      position: relative;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include transition(0.3s ease-in-out);
        border-top: 10px solid transparent;
      }
    }
    &:hover span::before{
      border-top-color: color(orange);
      // background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), transparent);
    }
  }
}

// PROJECT DETAILS PAGE
.tmv__project-dtls{
  .layout-hero{
    color: color(white);
    position: relative;
    // &::after{
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), transparent);
    //   z-index: 1;
    //   pointer-events: none;
    // }
  
    .project-count,
    .project-title{
      position: absolute;
      z-index: 3;
      @media(min-width: ($containerWidth + 1) ){
        left: calc(1 * (calc(100vw - var(--container)) / 2));
      }
      @media(max-width: $containerWidth){
        left: calc(1 * var(--containerGutter));
      }
    }

    .project-count{
      font-size: 250px;
      line-height: 0.8;
      letter-spacing: -20.5px;
      bottom: var(--headerGutter);
      @media(max-width: $mq-ipadL){
        font-size: 150px;
        letter-spacing: normal;
      }
      @media(max-width: $mq-mobL){
        font-size: 90px;
      }
    }
    .project-title{
      top: 50px;
      font-size: 70px;
      @media(max-width: $mq-ipadL){
        font-size: 50px;
      }
      @media(max-width: $mq-mobL){
        font-size: 30px;
      }
    }
  }

  .layout:not(.layout-hero){
    padding-top: var(--headerGutter);
    padding-bottom: var(--headerGutter);
    // figure{
    //   height: 100%;
    // }
    img{
      max-height: 100%;
    }
    // &::before{
    //   content: attr(class);
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   font-size: 100px;
    // }
  }

  
  .layout-1,
  .layout-2{
    display:grid;
    grid-template-columns: 49% auto;
    justify-content: space-between;
    figure{
      text-align: right;
      align-self: end;
    }
  }

  .layout-3,
  .layout-4{
    display:grid;
    grid-template-columns: 30% auto;
    justify-content: space-between;
    figure{
      text-align: right;
      align-self: end;
    }
  }

  .layout-5,
  .layout-6{
    display:grid;
    grid-template-columns: auto 30%;
    justify-content: space-between;
    figure{
      align-self: end;
    }
  }

  .layout-8,
  .layout-9,
  .layout-13,
  .layout-14{
    text-align: right;
  }

  .layout-9,
  .layout-10,
  .layout-11,
  .layout-14{
    display:grid;
    grid-template-columns: auto;
    figure{
      align-self: end;
    }
  }

  .layout-15,
  .layout-16,
  .layout-17,
  .layout-18{
    display:grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 0 var(--containerGutter);
  }

  .layout-15,
  .layout-17{
    figure:last-child{
      align-self: end;
      text-align: right;
    }
  }

  .layout-16,
  .layout-18{
    figure:first-child{
      align-self: end;
    }
  }

  .layout-20{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    article{
      width: 31%;
    }
  }

  .layout-21{
    @include d-flex(c, c, c);
  }
  
  .layout-22,
  .layout-23,
  .layout-24,
  .layout-25{
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 var(--containerGutter);
    figure:last-child{
      text-align: right;
    }
  }

  .layout-23,
  .layout-25{
    figure{
      align-self: end;
    }
  }

  .layout-26,
  .layout-28{
    text-align: right;
  }
  
  .layout-30,
  .layout-32{
    display:grid;
    grid-template-columns: 49% auto;
    justify-content: space-between;
    gap: 0 var(--containerGutter);
    figure{
      text-align: right;
    }
  }

  .layout-30{
    grid-template-columns: 49% auto;
  }

  .layout-32{
    grid-template-columns: 31% auto;
  }
  
  .layout-31,
  .layout-33{
    display:grid;
    justify-content: start;
    gap: 0 var(--containerGutter);
  }

  .layout-31{
    grid-template-columns: auto 49%;
  }

  .layout-33{
    grid-template-columns: auto 31%;
  }

  .layout-34{
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
    article{
      max-width: 785px;
      column-count: 2;
      column-gap: calc(var(--containerGutter) * 2);
    }
  }
  .layout-35{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    article{
      width: 49%;
    }
  }

  @media(max-width: $mq-ipadL){
    .layout-34{
      @include align-items(flex-start);
      height: auto !important;
      min-height: auto !important;
    }

    .layout-1,
    .layout-2,
    .layout-3,
    .layout-4,
    .layout-5,
    .layout-6,
    .layout-30,
    .layout-31,
    .layout-32,
    .layout-33,
    .layout-34{
      article {
        padding-right: var(--gapForMenu);
      }
    }
  }
  @media(max-width: $mq-mobL){
    .layout-19{
      @include fullH();
      @include d-flex(c, c, c);
      figure{
        padding-left: var(--containerGutter);
        padding-right: var(--containerGutter);
      }
    }
    .layout-34,
    .layout-35{
      margin-bottom: 200px;
    }
    .layout-35{
      position: static;
      article{
        width: 49%;
      }
    }
    .layout-20{
      article{
        width: 100%;
      }
    }
    .layout-22 {
      @include fullH();
      @include d-flex(c, c, c);
      gap: 0 !important;
      figure{
        width: 100%;
        height: calc(40vh - calc(var(--headerGutter) * 2));
        position: relative;
        &:first-child{
          margin-bottom: var(--headerGutter);
        }
        img{
          @include object(cover, center);
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
        }
      }
    }
  }

  @media(max-width: $mq-mobP){
    .layout:not(.layout-hero){
      .fullScreen{
        height: auto !important;
        min-height: auto !important;
        max-height: none !important;
      }
      // figure{
      //   padding-bottom: 70%;
      //   height: 0;
      //   position: relative;
      //   img{
      //     @include object(cover, top);
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //   }
      // }
    }
    .layout-2{
      grid-template-columns: repeat(1, 1fr);
      gap: 150px;
    }
    figure{
      img{
        width: 100%;
      }
    }
    .layout-1,
    .layout-3,
    .layout-4,
    .layout-5,
    .layout-6,
    .layout-9,
    .layout-10,
    .layout-11,
    .layout-14,
    .layout-15,
    .layout-16,
    .layout-17,
    .layout-18,
    .layout-22,
    .layout-23,
    .layout-24,
    .layout-25,
    .layout-30,
    .layout-31,
    .layout-32,
    .layout-33,
    .layout-34{
      grid-template-columns: repeat(1, 1fr);
      gap: var(--containerGutter);
    }
    .layout-34{
      article{
        column-count: 1;
      }
    }
    .layout-35{
      article{
        width: 100%;
      }
    }
  }
}

// PRACTICE PAGE
.tmv__practice {

    .layout {
        padding: var(--gutterY) var(--gutterX);

        ul {
            @media(max-width: $mq-ipadL ){
                padding-left: var(--containerGutter) ;
            }
        }

    }
    .layout-1,
    .layout-3 {
        display:grid;
        justify-content: space-between;
        @media(min-width: $mq-ipadL ){
            grid-template-columns: 49% auto;
        }
        .main-container.left-container {
            @media(min-width: $mq-mobL ){
                padding-left: max(calc( (100vw - var(--container))/2 ), var(--containerGutter) );
            }
        }
        article {
            display: flex;
            flex-direction: column;
            padding-bottom: var(--containerGutter);
            height: 100%;
            @media(min-width: $mq-ipadL ){
                padding-bottom: 80px;
            }
            h3 {
                margin-top: var(--containerGutter);
                margin-bottom: auto;
                font-weight: bold;
            }
            div.content {
                @media(min-width: $mq-ipadL ){
                    width: 50%;
                }
            }
        }
        figure{
            text-align: right;
            align-self: end;
            @media(min-width: $mq-ipadL ){
                align-self: start;
                width: 945px;
                height: 630px;
                position: relative;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    object-fit: cover;
                }
            }
        }
    }
    .layout-2,
    .layout-4 {
        display:grid;
        justify-content: space-between;
        @media(min-width: $mq-ipadL ){
            grid-template-columns: 49% 51%;
        }
        .main-container.left-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            @media(min-width: $mq-mobL ){
                padding-left: 0;
                margin-left: 0;
                article {
                    display: none;
                }
            }
            h3 {
                margin-top: var(--containerGutter);
                margin-bottom: auto;
                font-weight: bold;
                @media(min-width: $mq-mobL ){
                    padding-left: max(calc( (100vw - var(--container))/2 ), var(--containerGutter) );
                } 
            }
            figure {
                display: none;
                @media(min-width: $mq-ipadL ){
                    display: block;
                    /*
                    width: 945px;
                    height: 630px;
                    position: relative;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top:0;
                        left: 0;
                        object-fit: cover;
                    }
                    */
                }
            }
        }
        .main-container.right-container {
            padding-right: max(calc( (100vw - var(--container))/2 ), var(--containerGutter) );
            display: none;
            min-width: 100%;
            @media(min-width: $mq-mobL ){
                display: block;
            }

        }
        article {
            display: flex;
            flex-direction: column;
            padding-top: var(--containerGutter);
            padding-bottom: var(--containerGutter);
            height: 100%;
            @media(min-width: $mq-mobL ){
                padding-bottom: 80px;
            }
            div.content {
                @media(min-width: $mq-mobL ){
                    margin-left: auto;
                }
                @media(min-width: $mq-ipadL ){
                    width: 50%;
                }
                
            }
        }
        figure{
            text-align: left;
            align-self: end;
            @media(min-width: $mq-mobL ){
                align-self: start;
            }
            &.figure_mobile {
                @media(min-width: $mq-ipadL ){
                    display: none;
                }
            }
        }
    }
    .layout-3 {
        article {
            h3 {
                margin-bottom: 0;
            }
            div.content {
                @media(min-width: $mq-ipadL ){
                    width: 60%;
                    margin-top: calc(100vh - 630px - 80px);
                    margin-left: auto;
                }
            }
        }
        figure {
            align-self: end;
        }
    }
    .layout-4 {
        .main-container.left-container {
            figure {
                @media(min-width: $mq-mobL ){
                    padding-left: max(calc( (100vw - var(--container))/2 ), var(--containerGutter) );
                    img {
                        max-width: 537px;
                    }
                    
                }
            }
        }
        .main-container.right-container {
            padding-left: max(calc(50vw - 945px + var(--containerGutter) ), var(--containerGutter));
            padding-top: max(calc(100vh - 807px ), var(--containerGutter) );
        }
        article {
            @media(min-width: $mq-mobL ){
                padding-top:0;
            }
            div.content {
                @media(min-width: $mq-mobL ){
                    margin-left: 0;
                    padding-top:0;
                }
                @media(min-width: $mq-ipadL ){
                    width: 380px;
                }
            }
        }
    }

}

.logo-grid{
  & > span{
    position: relative;
    span{
      width: 100%;
      opacity: 0;
    }
    & > img{
      position: absolute;
      
    }
  }
  #t1{
    width: calc(46.5% - 15px); top: 0; left: 0;
  }
  #t2{
    width: calc(46.5% - 15px); top: 0; right: 0;
  }
  #t3{
    height: calc(100% - 30px); left: 48.5%; bottom: 0;
  }

  #m1{
    width: 48%; top: 0; left: 0;
  }
  #m2{
    width: 48%; top: 0; right: 0;
  }

  #v1{
    width: 48%; top: 0; left: 0;
  }
  #v2{
    width: 48%; top: 0; right: 0;
  }
}


                        
.videobg {
  position: relative;
  width: 100%; /* Set video container element width here */
  height: 100vh; /* Set video container element height here */
  overflow: hidden;
    pointer-events: none;
}

/* horizontally center the video */
.videobg-width {
  position: absolute;
  width: 100%; /* Change width value to cover more area*/
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

/* set video aspect ratio and vertically center */
.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 ratio */
  overflow: hidden;

}

.videobg-make-height {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

.videobg-hide-controls {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  /* Vimeo timeline and play button are ~55px high */
  padding: 55px 97.7777px; /* 16:9 ratio */
  top: -55px; 
  left: -97.7777px; /* 16:9 ratio */
}

.videobg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0 none;
}


.moveToContact{
  position: absolute;
  // bottom: 50%;
  // left: 100px;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
}

@media(max-width: $mq-mobL){
  body .iScrollIndicator{
    display: none !important;
  }
}
