// BUTTON STYLE
*[class*=btn-]{
    @include transition(0.5s); 
    &:hover{@include transition(0.5s);}
}

.btns-container{
    .btn:not(:first-child){margin-left:15px;}
}

// .container{
//     .btns-container{
//         width:calc(100% + 50px) !important;
//         left: -25px !important;
//     }
// } 

*[class*=cell-]{
    .btns-container{
        width:calc(100% + 50px) !important;
        left: -25px !important;
    }
} 

.btn, .link{
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    @include transition(0.3s);
}

.btn{
    padding: 0.5em 1.1em;
    border:1px solid transparent;
    @each $colorName, $colorValue in $allColors{
        &.btn-#{$colorName}{
            @extend .bg-#{$colorName};
            @extend .c-#{$colorName};
            // border-color: $colorValue;
            &.outline, &.outlineB{
                border-color: $colorValue;
                &:hover{
                    @extend .bg-#{$colorName};
                    border-color: $colorValue;
                }
            }
            &.outline{
                &:hover{
                    color: color(white);
                }
            }
            &.outlineB{
                &:hover{
                    color: color(black);
                }
            }
        }
    }
    &.outline, &.outlineB{
        background-color: transparent;
    }
    &.solid{
        color: color(white);
    }
    &.solidB{  
        color: color(black);
    }
    @include box-shadow(0px 0px 0px 0px transparent);

    &.solid, &.solidB{
        &:hover,
        &:focus{
            filter: brightness(160%) !important;
            -webkit-filter: brightness(160%) !important;
        }
    }

    &:hover,
    &:focus{
        @include box-shadow(0 14px 28px transparent, 0 10px 10px rgba(0,0,0,.18));
    }
    &.rounded{
        @include border-radius(0.4em);
    }
    &.small{
        font-size: 16px;
        padding: 0.5em 1.1em;
    }
    &.xl{
        @include typo(14, 22);  
        padding: 0.9em 1.5em;
    }
    &.btn-arw{
        span{
            top: -2px;
        }
    }
}

.link{
    padding-bottom: 0.1em;
    position: relative;
    font-size: inherit;
    background-color: transparent;
    &::before{
        content: '';
        display: inline-block;
        width: 0%;
        height: 1px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid currentColor;
        @include transition(0.5s);
    }

    @each $colorName, $colorValue in $allColors{
        &.link-#{$colorName}{
            @extend .c-#{$colorName};
            &::before{
                @extend .bg-#{$colorName};
            }
        }
    }
}

.btn-arw{
    padding-right: 45px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 40px;
        top: 0;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: 20px;
        @include transition(inherit);
    }
    &.right{
       
        // &::after{
        //     background-position: 8px 4px;
        //     background-image: url('./../img/icons/arwRW.svg');
        // }
        // &.dark::after{
        //     background-image: url('./../img/icons/arwRB.svg');
        // }
        &:hover::after{
            background-position: 20px 6px;
        }
    }
    
    &.left{
        
        // &::after{
        //     background-position: 12px 6px;
        //     background-image: url('./../img/icons/arwLW.svg');
        // }
        // &.dark::after{
        //     background-image: url('./../img/icons/arwLB.svg');
        // }
        &:hover::after{
            background-position: 5px 6px;
        }
    }

    &:empty{
        &::after{
            background-position: 0 6px;
        }
        height: 30px;
    }
    
}

// .btn-share{
//     display: inline-block;
//     cursor: pointer;
//    span{
//     width:20px;
//     height: 20px;
//     background-image: url('./../img/icons/share.svg');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//    }
// }

.btn-loadmore{
    text-align: center;
    margin-top: 50px;
    @include transition(0.3s);
    &:hover{
        //letter-spacing: 1px;
        span{
           top: 10px; 
        }
    }
    span{
        position: relative;
        top: 0;
        margin-top: 10px;
        width: 15px;
        height: 15px;
        //background-image: url('./../img/icons/down-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        will-change: top;
        @include transition(inherit);
    }
}

.btn--b2t{
    width: 44px;
    height: 42px;
    background-image: url('./../img/icons/b2t.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    margin-top: 73px;
}



#btn--scroll-down{
    //background-image: url('./../img/icons/arwDown.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    height: 45px;
    @include center(X);
    bottom: 52px;
    z-index: 1;
}

.btn--loadmore{
    @include i-flex(r, c, c);
    &::before{
        content: '';
        width: 40px;
        height: 40px;
        display: inline-block;
        background-image: url('./../img/icons/tmv-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40px;
   }
   
   @media(min-width: 1401px){
        @include transform(rotate(-90deg));
        transform-origin: bottom;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        &::before{
            margin-right: 10px;
            @include transform(rotate(-180deg) translateY(-1px));
        }
   }

   @media(max-width: 1400px){
        margin: 0 auto;
        &::before{
            @include transform(rotate(90deg) translateY(3px));
        }
  }
}