.vh{
    @include fullH();
}
  
.fullW{width: 100% !important; float: left;}
.fullH{@extend .vh;}
.noScrl{max-height: 100%; overflow: hidden;}
.radius0{border-radius: 0 !important;}

.fullScreen{width:100%; @extend .vh;}
img.fullScreen{object-fit: cover; object-position: center;}

.w-100{width: 100%;}

.fl-left{float: left !important;}
.fl-right{float: right !important;}
.fl-none{float: none !important;}



.center-container{
    @extend .fullW;
    @extend .text-center;
}

.equal-height{
    @include flex();
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include align-items(stretch);
    @include flex-wrap(wrap);
}


.d-none{
    display: none;
}
.d-inline{
    display: inline;
}
.d-inline-block{
    display: inline-block;
}
.d-block{
    display: block;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

.bg-transparent{background-color: transparent !important;}
.posiR{position: relative !important;}
.text-center{text-align: center !important;}
.text-left{text-align: left !important;}
.text-right{text-align: right !important;}
.text-justify{text-align: justify !important;}
.text-up{text-transform: uppercase;}
.text-low{text-transform: lowercase;}

.no-shadow{@include box-shadow(none !important);}

.no-border{border: none !important;}

body .m-0{margin: 0px !important;}
body .mt-0{margin-top: 0px !important;}
body .mb-0{margin-bottom: 0px !important;}
body .ml-0{margin-left: 0px !important;}
body .mr-0{margin-right: 0px !important;}

body .p-0{padding: 0px !important;}
body .pt-0{padding-top: 0px !important;}
body .pb-0{padding-bottom: 0px !important;}
body .pl-0{padding-left: 0px !important;}
body .pr-0{padding-right: 0px !important;}


body .b-0{border: none !important;}
body .bt-0{border-top: none !important;}
body .bb-0{border-bottom: none !important;}
body .bl-0{border-left: none !important;}
body .br-0{border-right: none !important;}


body .mt-4{margin-top: 4px !important;}
body .mt-5{margin-top: 5px !important;}
body .mt-10{margin-top: 10px !important;}
body .mt-15{margin-top: 15px !important;}
body .mt-20{margin-top: 20px !important;}
body .mt-30{margin-top: 30px !important;}
body .mt-35{margin-top: 35px !important;}
body .mt-150{margin-top: 150px !important;}


body .mb-5{margin-bottom: 5px !important;}
body .mb-10{margin-bottom: 10px !important;}
body .mb-20{margin-bottom: 20px !important;}
body .mb-25{margin-bottom: 25px !important;}
body .mb-30{margin-bottom: 30px !important;}
body .mb-50{margin-bottom: 50px !important;}

.vcenter{
    @include vcenter();
  }
.center{
    @include flex();
    @include justify-content(flex-start);
    @include align-items(center);
    @include flex-direction(row);
    & > *:not(:last-child){
        margin-right: 15px;
    }
    p{
        color: color(txt2);
    }
}
  


//   HOVER EFFECTS
.img-hover-zoom {
    overflow: hidden;
}
.img-hover-zoom img {
    @include transition(6s linear);
}
.img-hover-zoom:hover img {
    @include transform(scale(1.8));
}

.icenter{
    @include d-flex(c, c, c);
}

